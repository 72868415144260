import React, { useState, useEffect } from "react";

const TableOfContents = () => {
  const [headings, setHeadings] = useState([]);
  const [activeId, setActiveId] = useState(null);

  useEffect(() => {
    // Select all headers (phase headers and h1)
    const phaseHeaders = Array.from(document.querySelectorAll(".bg-cream .uppercase"));
    const h1Headings = Array.from(document.querySelectorAll("h1.font-semibold"));

    // Combine phase headers and h1 headings
    const allHeadings = [...phaseHeaders, ...h1Headings];

    // Create heading objects with unique IDs
    const headingsArray = allHeadings.map((el) => ({
      id: el.id || el.textContent.toLowerCase().replace(/\s+/g, "-"),
      text: el.textContent.trim(),
      level: el.tagName === "H1" ? 1 : 2,
    }));

    // Ensure headings have unique IDs if they're missing
    allHeadings.forEach((el, idx) => {
      if (!el.id) {
        el.id = headingsArray[idx].id;
      }
    });

    // Manually arrange headings here (for example, you could reorder them)
    const manuallyOrderedHeadings = [
      headingsArray[5],  // 2nd heading in the original order
      headingsArray[0],  // 1st heading
      headingsArray[6],
      headingsArray[7],
      headingsArray[1],
      headingsArray[8],
      headingsArray[9], 
      headingsArray[10], 
      headingsArray[2], 
      headingsArray[11],
      headingsArray[3],
      headingsArray[12], 
      headingsArray[13],
    ];

    setHeadings(manuallyOrderedHeadings); // Set the custom order for headings
  }, []);

  // Handle the smooth scroll for TOC item clicks
  const handleScroll = (event) => {
    event.preventDefault();
    const targetId = event.target.getAttribute("href").slice(1);
    const targetElement = document.getElementById(targetId);
    if (targetElement) {
      targetElement.scrollIntoView({ behavior: "smooth" });
    }
  };

  // Intersection Observer to track active section
  useEffect(() => {
    const options = {
      root: null, // Use the viewport
      threshold: 0.5, // Trigger when 50% of the element is in the viewport
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setActiveId(entry.target.id);
        }
      });
    }, options);

    // Observe all heading elements
    headings.forEach((heading) => {
      const element = document.getElementById(heading.id);
      if (element) {
        observer.observe(element);
      }
    });

    return () => {
      // Cleanup observers on component unmount
      headings.forEach((heading) => {
        const element = document.getElementById(heading.id);
        if (element) {
          observer.unobserve(element);
        }
      });
    };
  }, [headings]);

  return (
    <div className="toc-wrapper">
      {/* Lines representing TOC items */}
      <div className="toc-lines">
        {headings.map((heading) => (
          <div
            key={heading.id}
            className={`toc-item-line ${activeId === heading.id ? "active" : ""}`}
          ></div>
        ))}
      </div>

      {/* Actual Table of Contents */}
      <nav className="toc">
        <ul>
          {headings.map((heading) => (
            <li
              key={heading.id}
              className={`toc-item level-${heading.level} ${activeId === heading.id ? "active" : ""}`}
            >
              <a href={`#${heading.id}`} onClick={handleScroll}>
                {heading.text}
              </a>
            </li>
          ))}
        </ul>
      </nav>
    </div>
  );
};

export default TableOfContents;




