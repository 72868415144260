import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/effect-cards';

import '../custom-styles.css'

import { EffectCards } from 'swiper/modules';

const About = () => {
  return (
    // everything on the page
    <div className='flex w-full flex-col md:p-12'>
        {/* box content and intro stuff */}
        <div className='group h-full w-auto p-12 flex flex-col md:flex-row'>
            {/* top section - box and intro */}
        <div className='flex justify-center items-center my-10 pt-12 w-full md:w-1/2'>
            {/* box contents container/}*/}
            <div className='group flex flex-col bg-low-emphasis-gray w-full rounded-[20px] p-8 mx-8 md:rounded-3xl'>
                {/* box contents - img/}*/}
                <div>
                    <img src='/about-content/Headshot-Mida-24-copy.jpeg' alt='my headshot' className='rounded-[12px] md:rounded-2xl'/>
                </div>
                {/* box contents - name and info */}
                <div>
                    <div className='text-primary-red font-bold text-lg pt-4'>
                        Fahmida Rahman
                    </div>
                    {/* location, email, linkedin */}
                    <div className='flex flex-row items-center'>
                        <ul>
                            <li>
                                <img src='/about-content/location.svg' alt='location icon' className='w-5 h-5 mb-1'/>
                            </li>
                            <li>
                                <img src='/about-content/email.svg' alt='email icon' className='w-5 h-5'/>
                            </li>
                            <li>
                                <img src='/about-content/linkedin.svg' alt='linkedin icon' className='w-5 h-5 mt-1'/>
                            </li>

                        </ul>
                        <ul>
                            <li className='ml-2'>Ann Arbor, MI</li>
                            <li className='ml-2'>fahmida@umich.edu</li>
                            <li className='ml-2 '>www.linkedin.com/in/midarahman</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        {/* whole intro title + paragraphs */}
        <div className='sm:justify-center md:w-2/3 md:space-x-12 md:pt-40 lg:pt-14'>
            {/* title before blurb */}
            <div className='text-primary-red text-2xl font-bold pt-8 md:px-12'>
                Hi there! 👋
            </div>
            {/* intro paragraphs about me */}
            <div className='text-primary-red'>
                <p className='py-2'>My name is Fahmida Rahman and I am a student at the University of Michigan studying User Experience Design with a minor in Entrepreneurship.</p>
                <p className='py-2'>With a big appetite for learning, product design is a perfect convergence of technology, human needs, and creativity for my passion and skills. Throughout my journey, I've developed a deep enjoyment for storytelling, collaborating, designing, and developing.</p>
                <p className='py-2'>Through my designs, I like to be intentional and create spaces where functionality, aesthetics, and practicality meet. My motto is to "elevate the everyday" which means to create accessible and usable solutions that connect and enhance the experiences for all users.  </p>
                <p className='py-2'>Please feel free to reach out to me! 😊</p>
            </div>
        </div>
        </div>
    
        
        {/* fun stuff: you can find me.. */}
        <div className='flex justify-start my-10 flex-col px-12'>
            <div className='text-primary-red text-2xl font-bold pt-8'>
                You can find me... 🔎
            </div>
            <div className='flex flex-col lg:flex-row lg:space-x-64'>
                <div>
                    <div className='text-p-red-alt font-semibold py-2 text-lg'>
                        <h2>Making memories 🫂</h2>
                    </div>
                {/* first swiper */}
                    <div className='flex justify-start'>
                        <>
                            <Swiper
                                effect={'cards'}
                                grabCursor={true}
                                modules={[EffectCards]}
                                className="mySwiper"
                            >
                            <SwiperSlide>
                                <img src='/about-content/disney-bereal.JPG' alt='disney world' className='w-full h-full object-cover'/>
                            </SwiperSlide>
                            <SwiperSlide>
                                <img src='/about-content/la-bereal.JPG' alt='japantown la'className='w-full h-full object-cover'/>
                            </SwiperSlide>
                            <SwiperSlide>
                                <img src='/about-content/picnic-bereal.JPG' alt='picnic'className='w-full h-full object-cover'/>
                            </SwiperSlide>
                            <SwiperSlide>
                                <img src='/about-content/chi-bereal.JPG' alt='chicago pier'className='w-full h-full object-cover'/>
                            </SwiperSlide>
                            <SwiperSlide>
                                <img src='/about-content/dinner-bereal.JPG' alt='dinner'className='w-full h-full object-cover'/>
                            </SwiperSlide>
                            <SwiperSlide>
                                <img src='/about-content/painting-bereal.JPG' alt='atlas painting'className='w-full h-full object-cover'/>
                            </SwiperSlide>
                            <SwiperSlide>
                                <img src='/about-content/msu-bereal.JPG' alt='msu banquet'className='w-full h-full object-cover'/>
                            </SwiperSlide>
                            <SwiperSlide>
                                <img src='/about-content/nypd-bereal.JPG' alt='nypd pizza'className='w-full h-full object-cover'/>
                            </SwiperSlide>
                            </Swiper>
                        </>
                    </div>
                </div>
                
            <div>
                {/* second 'find me' header */}
                <div className='text-p-red-alt font-semibold py-2 text-lg'>
                    <h2>Listening to Music 🎶</h2>
                </div>
                {/* fav songs swiper*/}
                {/* first swiper */}
                <div className='flex flex-col justify-end'>
                    {/* <caption className='caption-top truncate'>
                        Some of my favorite songs
                    </caption> */}
                    <>
                        <Swiper
                            effect={'cards'}
                            grabCursor={true}
                            modules={[EffectCards]}
                            className="mySwiper"
                        >
                        <SwiperSlide>
                            <img src='/about-content/saturn.jpg' alt='disney world' className='rounded-[26px]'/>
                        </SwiperSlide>
                        <SwiperSlide>
                            <img src='/about-content/cologne.jpg' alt='japantown la' className='rounded-[26px]'/>
                        </SwiperSlide>
                        <SwiperSlide>
                            <img src='/about-content/let-me-go.jpg' alt='picnic' className='rounded-[26px]'/>
                        </SwiperSlide>
                        <SwiperSlide>
                            <img src='/about-content/chihiro.jpg' alt='chicago pier' className='rounded-[26px]'/>
                        </SwiperSlide>
                        <SwiperSlide>
                            <img src='/about-content/die-hard.jpg' alt='dinner' className='rounded-[26px]'/>
                        </SwiperSlide>
                        <SwiperSlide>
                            <img src='/about-content/so-good.jpg' alt='atlas painting' className='rounded-[26px]'/>
                        </SwiperSlide>
                        </Swiper>
                    </>
                </div>
            </div>
        </div>
        </div>
            

        {/* mini resume */}
        <div className='pt-8 p-12'>
            {/* experiences */}
            <div>
                <h1 className='text-primary-red uppercase font-bold text-lg'>experiences</h1>
                <div className='py-2'>
                    <p className='italic text-primary-red '>Aug 2024 - Present</p>
                    <h2 className='text-primary-red font-medium'>SI 539 Instructional Aide ✽ University of Michigan</h2>
                </div>
                <div className='py-2'>
                    <p className='italic text-primary-red '>Feb 2024 - Present</p>
                    <h2 className='text-primary-red font-medium'>Director of Design ✽ Atlas Digital Tech Consulting</h2>
                    <p className='text-primary-red italic'>Prev. UX/UI Designer</p>
                </div>
                <div className='py-2'>
                    <p className='text-primary-red italic'>Jan 2023 - Present</p>
                    <h2 className='text-primary-red font-medium'>UX/UI Designer ✽ Michigan Open UX</h2>
                    <p className='text-primary-red italic'>Prev. UX Fellow</p>
                </div>
                <div className='py-2'>
                    <p className='italic text-primary-red '>Sep 2023 - Present</p>
                    <h2 className='text-primary-red font-medium'>Director of Marketing Design ✽ Asia Business Conference</h2>
                    <p className='text-primary-red italic'>Prev. Associate Director of Marketing Design</p>
                </div>
            </div>
            {/* education */}
            <div>

            </div>
        </div>
    </div>
  )
}

export default About